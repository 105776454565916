/**
 * @steging @credentials
 */

export const ACTIVE_NETWORK = 8999;
export const RPC_URL = "https://rpc1-testnet.chromescan.org/"; // steging server
export const NetworkContextName = "Chromescan_Test_Network"; //steging
// Chromechain Mainnet
export const tokenAddress = "0xF91e083F54D078CD58530941F6095DD1cbB2dEA9"; //steging server
export const adminAddress = "0xf26f0Bf9AD45f8aC0Ca6ebE1484B2231837AE162"; //steging server
// export const tokenAddress = "0x8DC0EBa072924bb51cf66288E5A66e8B9a3c2596"; //live server
// export const adminAddress = "0xfB42e4dD7A2bCDA1a7DC198ED71729bE55F14539"; //live server
// 0xCFc4e2b5Cf89c6D67cb0880E5963D2114aCCc476
export const cscPriceAPI =
  "https://dashboard.cccmining.org/api/tenant/b164ff9b-47d2-4b22-b3ec-3d0c69973188/exchange-rate?limit=1&api_key=79c9feda-7d18-47ee-bd82-c6e6f2221558-3cfe0363-352e-4e54-b1a9-5e9db7b0c89f";

//---#ACTIVE-NETWORK-DETAILS#---//
export const NetworkDetails = [
  {
    // chainId: "8998", //live
    chainId: "0x2327", //server
    chainName: NetworkContextName,
    nativeCurrency: {
      name: "CSC",
      symbol: "CSC",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://mainnet.chromescan.org/"],
  },
];

/**
 * @live @credentials
 */

// export const ACTIVE_NETWORK = 8998;
// export const RPC_URL = "https://rpc1.chromescan.org/";
// export const NetworkContextName = "Chromechain Mainnet";
// // Chromechain Mainnet
// export const tokenAddress = "0x8DC0EBa072924bb51cf66288E5A66e8B9a3c2596";
// export const adminAddress = "0xfB42e4dD7A2bCDA1a7DC198ED71729bE55F14539";
// // 0xCFc4e2b5Cf89c6D67cb0880E5963D2114aCCc476
// export const cscPriceAPI =
//   "https://dashboard.cccmining.org/api/tenant/b164ff9b-47d2-4b22-b3ec-3d0c69973188/exchange-rate?limit=1&api_key=79c9feda-7d18-47ee-bd82-c6e6f2221558-3cfe0363-352e-4e54-b1a9-5e9db7b0c89f";

// //---#ACTIVE-NETWORK-DETAILS#---//
// export const NetworkDetails = [
//   {
//     chainId: "0x2326",
//     chainName: NetworkContextName,
//     nativeCurrency: {
//       name: "CSC",
//       symbol: "CSC",
//       decimals: 18,
//     },
//     rpcUrls: [RPC_URL],
//     blockExplorerUrls: ["https://mainnet.chromescan.org/"],
//   },
// ];

import React, {useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import sessionService from "./SessionService";

export default function SessionLogout({ children }) {
  const history = useHistory();
  // const [lastActiveTime, setLastActiveTime] = useState(new Date());

  // Add your excluded paths to this array
  const excludedPaths = [
    "/",
    "/login",
    "/register/:inviteCode?",
    "/signup-otp",
    "/verify-otp",
    "/reset-password",
    "/changePassword",
    "/forgot-password",
    "/privacy-policy",
    "/404",
    "/terms&condition",
  ];

  const currentPath = window.location.pathname;
  const isExcludedPath = excludedPaths.includes(currentPath);

  const handleUserActivity = () => {
    // setLastActiveTime(new Date());
    sessionService.clearTimers();
    sessionService.startTimers(handleSessionTimeout, handleIdleTimeout);
  };

  const handleSessionTimeout = () => {
    if (
      !sessionService.sessionExpired &&
      !sessionService.sessionToastDisplayed
    ) {
      history.push("/login");
      sessionService.clearTimers();
      sessionService.setSessionExpired(true);
      sessionService.setSessionToastDisplayed(true);
      toast.error("Session has expired.");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("creatturAccessToken");
      window.localStorage.clear();
    }
  };

  const handleIdleTimeout = () => {
    if (!sessionService.idleExpired && !sessionService.idleToastDisplayed) {
      sessionService.clearTimers();
      sessionService.setIdleExpired(true);
      sessionService.setIdleToastDisplayed(true);
      history.push("/login");
      toast.error("Session has expired due to inactivity.");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("creatturAccessToken");
      window.localStorage.clear();
    }
  };

  useEffect(() => {
    sessionService.startTimers(handleSessionTimeout, handleIdleTimeout);
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);
    return () => {
      sessionService.clearTimers();
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    if (!isExcludedPath) {
      handleUserActivity();
    }
  }, [isExcludedPath]);

  if (isExcludedPath) {
    return <div>{children}</div>;
  }

  return <div>{children}</div>;
}

import {  Typography, Box } from "@material-ui/core";
import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieComponent = () => {
  //   const onAcceptCookie = () => {
  //     CookieConsent.onAccept();
  //   };
  //   useEffect(() => {
  //     CookieConsent({
  //       cookie: {
  //         name: "my-cookie-consent",
  //         domain: "your-domain.com",
  //       },
  //       content: {
  //         message:
  //           "This website uses cookies to ensure you get the best experience.",
  //         dismiss: "Got it!",
  //         deny: "Reject",
  //         link: "Learn more",
  //         href: "/cookie-policy",
  //       },
  //       elements: {
  //         dismiss:
  //           '<button aria-label="dismiss cookie message" type="button" class="cc-btn cc-dismiss">{{dismiss}}</button>',
  //         deny: '<button aria-label="deny cookies" type="button" class="cc-btn cc-deny">{{deny}}</button>',
  //       },
  //       onStatusChange: (status, chosenOptions) => {
  //         // Handle the user's choice here
  //         console.log("Cookie consent status:", status);
  //       },
  //     });
  //   }, []);
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Reject"
        buttonClasses="btn cookiesButton"
        containerClasses="alert alert-warning col-lg-12"
        contentClasses="text-capitalize"
        cookieName="CHROMESCAN"
        style={{
          background: "#fff",
          padding: "20px",
          alignItems: "end",
          width: "auto",
          zIndex: "99999",
        }}
        //   onAccept={CookieConsent.onAccept()}
      >
        <Box>
          <Typography
            variant="h6"
            style={{ color: "#0B1426", fontWeight: "500" }}
            mb={2}
          >
           Cookie Acceptance Notice
          </Typography>

          <Typography variant="body1" style={{ color: "#000" }}>
          We use cookies to personalize content, analyze our traffic, 
          and enhance your browsing experience. By clicking 'Accept,' 
          you agree to the use of cookies on our site. 
          If you have any questions, please contact us.
           {/* <a 
            
                  href=""
                  target="_blank"
                  style={{
                    cursor: "pointer",
                  }}
          > 
          [contact us]
           </a>.  */}
          </Typography>
        </Box>
        {/* <Box align="right">
        <Button variant="contained" color="primary" onClick={onAcceptCookie}>
          Accept
        </Button>{" "}
        &nbsp; &nbsp;
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
      </Box> */}
      </CookieConsent>
    </>
  );
};

export default CookieComponent;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {
  Drawer,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Link as ReactScrollLink } from "react-scroll";
import { FiMenu } from "react-icons/fi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "block",
    "@media(max-width:1278px)": {
      display: "none",
    },
  },
  root1: {
    display: "none",
    "@media(max-width:1278px)": {
      display: "block",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 70px",
  },
  linkButtonsDiv: {
    display: "flex",
    gap: "32px",
  },
  logo: {
    width: "110px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "100px",
      padding: "10px 0",
    },
  },

  register: {
    background: "linear-gradient(89.1deg, #00F0FF 1.02%, #1E92AA 72.36%)",
    borderRadius: "20px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15.2381px",
    lineHeight: "18px",
    color: "#FFFFFF",
    cursor: "pointer",
    padding: "12px 24px",
    textTransform: "none",
    minWidth: "200px",
  },
  Login: {
    // fontFamily: "Poppins",
    // borderRadius: "20px",
    // fontStyle: "normal",
    // fontWeight: "700",
    // fontSize: "15.2381px",
    // lineHeight: "18px",
    // color: "#FDFAFE",
    // cursor: "pointer",
    // // background: "linear-gradient(320deg, #1F7C99 0%, #15D7D7 100%)",
    // // "-webkit-background-clip": "text",
    // // "-webkit-text-fill-color": "transparent",
    // // backgroundClip: "text",
    // // textFillColor: "transparent",
    // // textTransform: "none",
    // border: '2px solid #FDFAFE',
    // minWidth: "200px"
    padding: "10px 25px",
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: "33.488px",
    borderRadius: "10px",
    background: "#129DFF",
    boxShadow:
      "0px 2.0930235385894775px 10.465118408203125px 4.186047077178955px rgba(0, 0, 0, 0.10)",
    textTransform: "capitalize",
  },
  linkButton: {
    fontFamily: "poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "27px",
    color: "#FDFAFE",
    cursor: "pointer",
    textTransform: "capitalize",
    "&::hover": {
      color: "red",
    },
  },
  linkButton1: {
    color: "#F3F3F3",
    fontSize: "13px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    padding: "10px 0",
    fontWeight: "400",
    lineHeight: "normal",
    textTransform: "capitalize",
  },

  registerBox: {
    display: "flex",
    gap: "20px",
  },
  drawerRight: {
    "& .MuiDrawer-paperAnchorRight": {
      background: "#161616",
      padding: "30px 20px",
    },
  },
  toolbarlogo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
}));

export default function TopBar() {
  const classes = useStyles();
  const [openDrawerContent, setOpenDrawerContent] = useState(false);
  const [logIn, setLogIn] = useState(false);
  const history = useHistory();
  const handleDrawerOpen = () => {
    setOpenDrawerContent(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawerContent(false);
  };
  const inviteCode = "";
  const signupUrl = `${"/register"}?inviteCode=${inviteCode}`;
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      setLogIn(true);
    } else setLogIn(false);
  }, [token]);

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" style={{ backgroundColor: "#0B1426" }}>
          <Toolbar id="id1" className={classes.toolbar}>
            {/* <Container> */}
            <Box>
              <ReactScrollLink to="footer">
                <img
                  className={classes.logo}
                  src="/images/logo.svg"
                  alt=""
                  onClick={() => history.push("/")}
                />
              </ReactScrollLink>
            </Box>

            <Box className={classes.linkButtonsDiv}>
              <Button
                className={classes.linkButton}
                onClick={() => history.push("/about-us")}
              >
                About Us
              </Button>
              <Button
                className={classes.linkButton}
                onClick={() => history.push("/keyfeatures")}
              >
                Key Features
              </Button>
              <Button
                className={classes.linkButton}
                onClick={() => history.push("/product")}
              >
                Products
              </Button>
              <Button
                className={classes.linkButton}
                onClick={() => history.push("/blog")}
              >
                Blogs & Media
              </Button>

              {/* <Button
                className={classes.linkButton}
                href="images/Vinay_Whitepaper.pdf"
                target="_blank"
              >
                Whitepaper
              </Button> */}
              {/* <Button className={classes.linkButton}>Social Media</Button> */}
            </Box>
            {logIn === true && (
              <Box className={classes.registerBox}>
                <Button
                  className={classes.Login}
                  onClick={() => history.push("/dashboard")}
                >
                  Dashboard
                </Button>
              </Box>
            )}
            {logIn === false && (
              <Box className={classes.registerBox}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ background: "#fff", color: "#000", minWidth:"130px" }}
                  onClick={() => history.push(signupUrl)}
                >
                  Register
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  // className={classes.Login}
                  style={{ background: "#129DFF", minWidth:"130px" }}
                  onClick={() => history.push("/login")}
                >
                  Login
                </Button>
              </Box>
            )}
            {/* </Container> */}
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.root1}>
        <AppBar position="static" style={{ backgroundColor: "#0B1426" }}>
          <Toolbar className={classes.toolbarlogo}>
            <Box>
              <img className={classes.logo} src="/images/logo.svg" alt=""/>
            </Box>
            <Box>
              <FiMenu
                onClick={handleDrawerOpen}
                style={{ color: "#fff", fontSize: "25px" }}
              />
            </Box>
          </Toolbar>
          <Drawer
            anchor="right"
            open={openDrawerContent}
            onClose={handleDrawerClose}
            className={classes.drawerRight}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.gridfour}
                style={{ width: "10rem" }}
              >
                <List>
                  <img className={classes.logo} src="/images/logo.svg" alt="" />
                  <ListItem
                    className={classes.linkButton1}
                    onClick={() => history.push("/about-us")}
                  >
                    About Us
                  </ListItem>
                  <ListItem
                    className={classes.linkButton1}
                    onClick={() => history.push("/keyfeatures")}
                  >
                    Key Features
                  </ListItem>
                  <ListItem
                    className={classes.linkButton1}
                    onClick={() => history.push("/product")}
                  >
                    Products
                  </ListItem>
                  <ListItem
                    className={classes.linkButton1}
                    onClick={() => history.push("/blog")}
                  >
                    Blogs & Media
                  </ListItem>
                  {/* <Button
                    style={{ color: "#fff" }}
                    className={classes.linkButton1}
                    href="images/Vinay_Whitepaper.pdf"
                    target="_blank"
                  >
                    Whitepaper
                  </Button> */}

                  {logIn === true && (
                    <ListItem
                      className={classes.Login}
                      onClick={() => history.push("/dashboard")}
                    >
                      Dashboard
                    </ListItem>
                  )}

                  {logIn === false && (
                    <ListItem
                      style={{ maxWidth: "100px", minWidth: "100px" }}
                      className={classes.Login}
                      onClick={() => history.push("/login")}
                    >
                      Login
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </Drawer>
        </AppBar>
      </div>
    </>
  );
}

import React  from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: "fit-content",
    maxWidth: "508px",
    overflowY: "auto",
    borderRadius: "10px",
    margin: "auto",
    marginBottom: "40px",
    marginTop: "60px",
    zIndex: "11",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "40px 0 40px 0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FD9F49",
      borderRadius: "20px !important",
    },
    "@media(max-width:600px)": {
      width: "88%",
      marginTop: "20px",
    },
  },
  left: {
    height: "100%",
    "@media(max-width:959px)": {
      display: "none",
    },
  },
  mainScreenBack: {
    // overflow: "hidden",
    position: "relative",
    background: theme.palette.background.newpaper,
  },
  logo: {
    cursor: "pointer !important",
    width: "149px",
    padding: "20px",
    // position: "absolute",
    // top: "0",
    zIndex: "10",
  },
  imageside: {
    width: "auto",
    height: "100%",
    maxWidth: "100%",
    position: "fixed",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    "@media (max-width: 950px)": {
      display: "none !important",
    },
  },
  firstscreen: {
    // overflowY: "auto",
    // height: "inherit",
    // display: "grid",
    background: theme.palette.background.newpaper,
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.mainScreenBack}>
      <Grid container style={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          className={classes.firstscreen}
        >
          <img
            className={classes.logo}
            src="images/logo.svg"
            alt=""
            onClick={() => history.push("/")}
          />

          <Box>
            <Paper elevation={2} className={classes.content}>
              {children}
              {/* <Box style={{ display: "grid", justifyContent: "center", alignItems:"end" }}>
              <Typography classname={classes.dontacctypo}>Don’t have an account? <span classname={classes.signupspan} onClick={() => { history.push("/register") }} style={{ cursor: "pointer" }}>Sign up</span></Typography>
            </Box> */}
            </Paper>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className={classes.sideimagegrid}
        >
          <Box>
            <img
              className={classes.imageside}
              src="/images/Sideimage.png"
              alt="Side_Image"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;

import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Dialog,
  Hidden,
  SvgIcon,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import Logo from "src/component/Logo";
import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#232B3B;",
    padding: "3px 0px",
  },
  rootLight: {
    padding: "3px 0px",
  },
  toolbar: {
    height: 70,
    "@media(max-width:550px)": {
      height: 90,
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  // divider: {
  //   width: 1,
  //   height: 32,
  //   marginLeft: theme.spacing(2),
  //   marginRight: theme.spacing(2),
  // },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    borderRadius: "25px",
    height: 266,
    background: "#004AAD",
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    color: theme.palette.secondary.main,
  },

  NotificationListStyle: {
    background: theme.palette.background.Notification,
    color: theme.palette.text.NotificationColor,
  },
  navbarIcon: {
    display: "flex",
  },
  linkcolor: {
    color: "#fff",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16.6667px",
    lineHeight: "29px",
  },
  iconColor: {
    "@media(max-width: 767px)": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
  search: {
    width: "303px",
    height: "42px",
    borderRadius: "42px",
    marginLeft: "50px",
  },
  search1: {
    height: "42px",
    borderRadius: "42px",
    marginLeft: "50px",
  },
  TextBox: {
    width: "303px",
    height: "42px",
    borderRadius: "42px",
    paddingRight: 14,
    fontSize: "16px",
    caretColor: "#000",
    background: "#fff",
    "&::placeholder": {
      color: "rgb(0,0,0,0.5)",
    },
    "& .MuiInputBase-input": {
      height: "30px",
      paddingLeft: "14px",
      paddingTop: "6px",
      paddingRight: "10px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important",
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: "10px",
    },
    "& .MuiInputBase-root": {
      border: "1px solid #585757",
    },
    "&::before": {
      borderBottom: "none", // Remove the underline
    },
  },
  TextBox1: {
    width: "303px",
    height: "42px",
    borderRadius: "42px",
    paddingRight: 14,
    fontSize: "16px",
    caretColor: "#fff",
    background: "#172031",
    "&::placeholder": {
      color: "rgb(255,255,255,0.5)",
    },
    "& .MuiInputBase-input": {
      height: "30px",
      paddingLeft: "14px",
      paddingTop: "6px",
      paddingRight: "10px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important",
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: "10px",
    },
    "& .MuiInputBase-root": {
      border: "1px solid #585757",
    },
    "&::before": {
      borderBottom: "none", // Remove the underline
    },
  },
  TextBoxsearch: {
    height: "42px",
    borderRadius: "42px",
    paddingRight: 14,
    fontSize: "16px",
    caretColor: "#000",
    background: "#fff",
    "&::placeholder": {
      color: "rgb(0,0,0,0.5)",
    },
    "& .MuiInputBase-input": {
      height: "30px",
      paddingLeft: "14px",
      paddingTop: "6px",
      paddingRight: "10px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important",
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: "10px",
    },
    "& .MuiInputBase-root": {
      border: "1px solid #585757",
    },
    "&::before": {
      borderBottom: "none", // Remove the underline
    },
  },
  TextBox1search: {
    height: "42px",
    borderRadius: "42px",
    paddingRight: 14,
    fontSize: "16px",
    caretColor: "#fff",
    background: "#172031",
    "&::placeholder": {
      color: "rgb(255,255,255,0.5)",
    },
    "& .MuiInputBase-input": {
      height: "30px",
      paddingLeft: "14px",
      paddingTop: "6px",
      paddingRight: "10px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important",
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: "10px",
    },
    "& .MuiInputBase-root": {
      border: "1px solid #585757",
    },
    "&::before": {
      borderBottom: "none", // Remove the underline
    },
  },
  priceBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "39px !important",
    maxWidth: "304px",
    borderRadius: "8px !important",
    padding: "3px 25px !important",
    marginRight: "12px",
    marginLeft: "10px",
    "& p": {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "36.409px",
      "@media(max-width:1280px)": {
        fontSize: "14px",
      },
      "@media(max-width:1030px)": {
        fontSize: "10px",
      },
    },
    "@media(max-width:550px)": {
      display: "none",
    },
  },
  priceBox11: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "39px",
    maxWidth: "304px",
    borderRadius: "8px !important",
    padding: "3px 5px !important",
    marginRight: "12px",
    marginLeft: "10px",
    "& p": {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "16.409px",
      margin: "0px",
      "@media(max-width:1280px)": {
        fontSize: "14px",
      },
      "@media(max-width:1030px)": {
        fontSize: "10px",
      },
    },
  },
  VisibaleDiv: {
    display: "none",
    "@media(max-width:550px)": {
      display: "block ",
    },
  },
  PriceBoxIcon: {
    display: "none",
    "@media(max-width:750px)": {
      display: "grid",
      fontSize: "40px",
      alignItems: "center",
    },
  },
  priceBox1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "304px",
    borderRadius: "15px",
    padding: "3px 25px !important",
    marginRight: "12px",
    "& p": {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "36.409px",
    },
  },
  searchBox: {
    "@media(max-width:750px)": {
      display: "none",
    },
  },
  searchBox1: {
    display: "none",
    "@media(max-width:750px)": {
      display: "block",
    },
  },
  searchBox1Search: {
    display: "block",
  },
  upperBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px",
    gap: "10px",
  },
  notificationTitle: {
    display: "flex",
    fontSize: "28px",
    fontStyle: "normal",
    alignItems: "center",
    fontWeight: "600",
    lineHeight: "38px",
    textTransform: "capitalize",
  },
  ViewAllBtnDiv: {
    display: "flex",
    justifyContent: "space-between",
    opacity: 1,
  },
  contentMsg: {
    display: "block",
  },
  viewAllBtn: {
    width: " 20%",
    height: "20%",
    padding: " 5px 10px",
  },
  listMenu: {
    display: "grid",
    gap: "10px",
    // padding: "0 34px",
    [theme.breakpoints.down("md")]: {
      // padding: "0, 20px",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: "0 16px",
    },
  },
  menuListText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "25px",
    textTransform: "capitalize",

    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  menuListSubText: {
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "12px",
    lineHeight: "25px",
    textTransform: "capitalize",

    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  menuListDateText: {
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "12px",
    lineHeight: "25px",
    textTransform: "capitalize",

    opacity: "0.7",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  BoxHeight: {
    padding: "6px 0 !important",
    borderBottom: "1px solid #80808094",
  },
  Round: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    background: "#FFF",
    marginRight: "6px",
    "@media(max-width:370px)": {
      display: "none",
    },
  },
  divider: {
    borderRight: "2px solid #172031",
    height: "35px",
    marginLeft: "8px",
    marginRight: "8px",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);

  return (
    <AppBar
      className={`${
        themeSeeting.settings.theme === "LIGHT"
          ? classes.rootLight
          : classes.root
      }`}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#FF2626"
            onClick={onMobileNavOpen}
            style={{ marginRight: 10 }}
          >
            <SvgIcon fontSize="small">
              <MenuIcon
                className={`${
                  themeSeeting.settings.theme === "LIGHT"
                    ? "typographyColorLight"
                    : "typographyColorDark"
                }`}
              />
            </SvgIcon>
          </IconButton>
        </Hidden>

        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const location = useLocation();
  const themeSeeting = useContext(SettingsContext);
  const [notiData, setNotiData] = useState([]);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  const notificationHandler = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.listNotification,
        params: { limit: 5 },
        headers: {
          token: token,
        },
      });

      if (res.data.statusCode == 200) {
        setNotiData(res?.data?.dataResults?.docs);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const [CoinData, setCoinData] = useState("");

  const [CoinDataValue, setCoinDataValue] = useState("");

  useEffect(() => {
    fetch("https://nodeexplorer.chromescan.org/get/price/percentage/value")
      .then((response) => response.json())
      .then((data) => {
        setCoinData(data);
      })
      .catch((error) => {
        setCoinData("");
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    fetch(
      "https://dashboard.cccmining.org/api/tenant/b164ff9b-47d2-4b22-b3ec-3d0c69973188/exchange-rate?limit=1&api_key=79c9feda-7d18-47ee-bd82-c6e6f2221558-3cfe0363-352e-4e54-b1a9-5e9db7b0c89f"
    )
      .then((response) => response.json())
      .then((data) => {
        setCoinDataValue(data.rows[0].valueInUSD);
      })
      .catch((error) => {
        setCoinDataValue("");
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <div className={classes.visibaleDivMain}>
        <Logo
          style={{
            paddingTop: "0px",

            cursor: "pointer",
            width: "112px",
            paddingLeft: "0",
          }}
        />
      </div>
      {location.pathname !== "/kyc" ? (
        <>
          <Box flexGrow={1} />
          <Box className={classes.searchBox}></Box>
          <Box flexGrow={2} />
          {/* {(CoinData && CoinDataValue) && ( */}
          <Box
            className={`${classes.priceBox} ${
              themeSeeting.settings.theme === "LIGHT"
                ? "cardColorDark"
                : "cardColorDark"
            }`}
          >
            <div className={classes.Round}></div>
            <p
              className={` ${
                themeSeeting.settings.theme === "LIGHT"
                  ? "typographyColorLight"
                  : "typographyColorDark"
              }`}
              style={{ margin: "0 10px 0px 0 " }}
            >
              CSC Price :
            </p>
            <p
              className={` ${
                themeSeeting.settings.theme === "LIGHT"
                  ? "typographyColorLight"
                  : "typographyColorDark"
              }`}
            >
              ${CoinDataValue ? CoinDataValue : "0"}{" "}
              <span
                style={
                  CoinData > 0
                    ? { color: "rgba(102, 255, 64, 0.85)" }
                    : { color: "rgb(254, 59, 59)" }
                }
              >
                ({CoinData ? (CoinData > 0 ? "+" + CoinData : CoinData) : "0"})
              </span>
            </p>
          </Box>
          {/* )} */}

          {/* <div className={classes.PriceBoxIcon}>
            <React.Fragment key={"Top"}>
              <MdOutlinePriceChange onClick={toggleDrawer("Top", true)} />
              <Drawer
                anchor={"Top"}
                open={state["Top"]}
                onClose={toggleDrawer("Top", false)}
              >
                <Box
                  className={classes.upperBox}
                  style={
                    themeSeeting.settings.theme === "LIGHT"
                      ? { background: "#172031" }
                      : { background: "#fff" }
                  }
                >
                  <Box className={classes.searchBox1Search}>                   
                  </Box>
                  <Box
                    className={`${classes.priceBox1} ${
                      themeSeeting.settings.theme === "LIGHT"
                        ? "cardColorLight"
                        : "cardColorDark"
                    }`}
                  >
                    <p
                      className={` ${
                        themeSeeting.settings.theme === "LIGHT"
                          ? "typographyColorLight"
                          : "typographyColorDark"
                      }`}
                    >
                      CUSD
                    </p>
                    <p
                      className={` ${
                        themeSeeting.settings.theme === "LIGHT"
                          ? "typographyColorLight"
                          : "typographyColorDark"
                      }`}
                    >
                      {setCryptoDecimals(tokenBalance)}
                    </p>
                    <p
                      className={` ${
                        themeSeeting.settings.theme === "LIGHT"
                          ? "typographyColorLight"
                          : "typographyColorDark"
                      }`}
                    >
                      CSC
                    </p>
                    <p
                      className={` ${
                        themeSeeting.settings.theme === "LIGHT"
                          ? "typographyColorLight"
                          : "typographyColorDark"
                      }`}
                    >
                      00.1
                    </p>
                  </Box>
                </Box>
              </Drawer>
            </React.Fragment>
          </div> */}
        </>
      ) : (
        <Box flexGrow={2}></Box>
      )}
      <Box>
        <IconButton
          onClick={() => {
            themeSeeting.settings.theme === "LIGHT"
              ? themeSeeting.saveSettings({
                  direction: "dtr",
                  responsiveFontSizes: true,
                  theme: "DARK",
                })
              : themeSeeting.saveSettings({
                  direction: "ltr",
                  responsiveFontSizes: true,
                  theme: "LIGHT",
                });
          }}
        >
          <Brightness4Icon style={{ fontSize: "36px" }} />
        </IconButton>
      </Box>
      <Box>
        <IconButton
          onClick={() => {
            setOpen(true);
            notificationHandler();
          }}
        >
          <img
            width="100%"
            style={{ width: "100%", maxWidth: "40px" }}
            src={
              themeSeeting.settings.theme === "LIGHT"
                ? "images/notificationDark.svg"
                : "images/notification.svg"
            }
            alt=""
          />
        </IconButton>
      </Box>
      {/* <SwipeableTemporaryDrawer /> */}
      <Dialog
        PaperComponent={Paper}
        PaperProps={
          themeSeeting.settings.theme === "LIGHT"
            ? {
                style: {
                  position: "fixed",
                  top: 20,
                  right: 0,
                  padding: "20px",
                  width: "100%",
                  maxWidth: "37vw",
                  background: "#fff",
                  borderRadius: "10px",
                  maxHeight: "333px",
                },
              }
            : {
                style: {
                  position: "fixed",
                  top: 20,
                  right: 0,
                  padding: "20px",
                  width: "100%",
                  maxWidth: "37vw",
                  background: "#172031",
                  borderRadius: "10px",
                  maxHeight: "333px",
                },
              }
        }
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        {notiData.length !== 0 ? (
          <>
            <div>
              <div className={classes.ViewAllBtnDiv}>
                <Typography
                  className={`${classes.notificationTitle} ${
                    themeSeeting.settings.theme === "LIGHT"
                      ? "typographyColorLight"
                      : "typographyColorDark"
                  }`}
                >
                  Announcements
                </Typography>
                {notiData.length === 0 ? (
                  ""
                ) : (
                  <Button
                    className={`${
                      themeSeeting.settings.theme === "LIGHT"
                        ? "dark "
                        : "light "
                    } ${classes.viewAllBtn}`}
                    onClick={() => {
                      history.push("/announcements");
                      setOpen(false);
                    }}
                  >
                    View All
                  </Button>
                )}
              </div>
            </div>
            <Box mt={4} className={classes.listMenu}>
              {notiData &&
                notiData?.map((value) => {
                  return (
                    <Box
                      className={`${classes.BoxHeight} ${
                        themeSeeting.settings.theme === "LIGHT"
                          ? "cardColorDarkOpacity"
                          : "cardColorLightOpacity"
                      }`}
                    >
                      {loader ? (
                        <CircularProgress size="20px" sx={{ color: "white" }} />
                      ) : (
                        ""
                      )}
                      <div className={classes.contentMsg}>
                        <Box>
                          <Typography
                            className={`${classes.menuListText} ${
                              themeSeeting.settings.theme === "LIGHT"
                                ? "typographyColorLight"
                                : "typographyColorDark"
                            }`}
                          >
                            {value?.announcementTitle}
                          </Typography>
                          {/* <Typography
                            className={`${classes.menuListSubText} ${
                              themeSeeting.settings.theme === "LIGHT"
                                ? "typographyColorLight"
                                : "typographyColorDark"
                            }`}
                          >
                            {value?.announcementDetails}
                          </Typography> */}

                          <Box
                            style={{ wordBreak: "break-word" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                value?.announcementDetails?.length > 80
                                  ? value?.announcementDetails?.substring(
                                      0,
                                      80
                                    ) + "..."
                                  : value?.announcementDetails,
                            }}
                          ></Box>
                        </Box>
                        <Box>
                          <Typography
                            className={`${classes.menuListDateText} ${
                              themeSeeting.settings.theme === "LIGHT"
                                ? "typographyColorLight"
                                : "typographyColorDark"
                            }`}
                          >
                            {moment(value?.createdAt).format(
                              "Do MMM YYYY, h:mm a"
                            )}
                          </Typography>
                        </Box>
                      </div>
                    </Box>
                  );
                })}
            </Box>
          </>
        ) : (
          <>
            <Typography
              className={`${classes.notificationTitle} ${
                themeSeeting.settings.theme === "LIGHT"
                  ? "typographyColorLight"
                  : "typographyColorDark"
              }`}
            >
              Announcements
            </Typography>
            <Box mt={4} className={classes.listMenu}>
              <Typography
                className={` ${
                  themeSeeting.settings.theme === "LIGHT"
                    ? "typographyColorLight"
                    : "typographyColorDark"
                }`}
                style={{
                  fontSize: "16px",

                  textAlign: "center",
                }}
              >
                No Records
              </Typography>
            </Box>
          </>
        )}
      </Dialog>
    </>
  );
}

import React, { createContext, useState, useEffect, useContext } from "react";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { injected } from "src/connectors";
import { ACTIVE_NETWORK, NetworkDetails, tokenAddress } from "src/constants";
import { getContract, getWeb3Obj } from "src/utils";
import IERC20ABI from "src/abi/IERC20ABI.json";
import { AuthContext } from "./Auth";
export const UserContext = createContext();

export default function AuthProvider(props) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [profile, setProfile] = useState({});
  const [kycStatus, setKycStatus] = useState();
  const [exchangeBalance, setExchangeBalance] = useState({});
  const [userWalletBalance, setUserWalletBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [binaryTreeUserName, setbinaryTreeUserName] = useState(0);

  const handleSave = (props) => {
    setbinaryTreeUserName(props);
  };
  const getViewMyProfile = async (values) => {
    const token = localStorage.getItem("token");

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewMyProfile,
        headers: { token: token },
      });
      if (res.data.responseCode === 200) {
        setProfile(res.data.result);
        setKycStatus(res.data.result.kycVerified);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      if (
        error.response.data.responseMessage === "Unauthorized person." ||
        error.response.data.responseMessage === "USER NOT FOUND" ||
        error.response.data.responseMessage ===
          "Session Expired, Please login again."
      ) {
        history.push("/login");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("email");
      } else {
        toast.error("Server down");
      }
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      auth.userLogIn(null, false);
    }
  };
  const exchangeWallet = async (values) => {
    const token = localStorage.getItem("token");

    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.exchangeWallet,
        headers: { token: token },
      });

      if (res.data.statusCode === 200) {
        setExchangeBalance(res.data.result);
      } else {
        // toast.error(res.data.responseMessage);
      }
    } catch (error) {}
  };

  const kycUpdated = (props) => {
    setKycStatus(props);
  };

  //-----*WEB3-Connection-Establisher*-----//
  const connectWalletHandler = () => {
    try {
      const connector = injected;
      localStorage.setItem("walletName", "METAMASK");
      sessionStorage.removeItem("walletName");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          // activate(connector);
          console.log("error", error);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.error(JSON.stringify(error.message));
    }
  };

  /*
  -----*WEB3-Connection-Disable*-----
   USE THIS FUNCTION IF YOU NEED TO ADD A DISCONNECT WALLET BUTTON
  */
  const disconnectWalletHandler = async () => {
    try {
      deactivate();

      window.localStorage.removeItem("walletName");
      window.open("/", "_self");
    } catch (error) {
      console.log(error);
    }
  };

  //-----*WEB3-Network-Change-Request*-----//
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  //-----*WEB3-Network-Add-Request*-----//
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
    }
  };

  // account balance -----//
  const getUserbalance = async () => {
    const web3 = await getWeb3Obj();
    const balance = await web3.eth.getBalance(account);

    const balanceImETH = await web3.utils.fromWei(balance);
    setUserWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  const getTokenWalletBalance = async () => {
    try {
      const web3 = await getWeb3Obj();
      const tokenObj = getContract(tokenAddress, IERC20ABI, library, account);
      const getTokenBalance = await tokenObj.balanceOf(account);
      const balancetoken = web3.utils.fromWei(getTokenBalance?.toString());

      setTokenBalance(balancetoken);
    } catch (error) {
      console.log(error);
    }

    // settokenBalance(parseFloat(balancetoken).toFixed(2));
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getViewMyProfile();
    }
  }, []);

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      connectWalletHandler();
    }
  }, [localStorage.getItem("walletName")]);

  useEffect(() => {
    if (account) {
      getTokenWalletBalance();
      getUserbalance();
    }
  }, [account]);

  let data = {
    profile,
    exchangeBalance,
    kycStatus,
    userWalletBalance,
    tokenBalance,
    binaryTreeUserName,
    refreshBalance: () => {
      getTokenWalletBalance();
      getUserbalance();
    },
    getViewMyProfile: () => getViewMyProfile(),
    kycUpdated: () => kycUpdated(),
    exchangeWallet: () => exchangeWallet(),
    connectWallet: () => connectWalletHandler(),
    disconnectWallet: () => disconnectWalletHandler(),
    handleSave: (props) => handleSave(props),
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}

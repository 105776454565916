import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/home/home")),
  },
  {
    exact: true,
    path: "/keyfeatures",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/keyfeatures")),
  },
  {
    exact: true,
    path: "/blog",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/blog")),
  },
  {
    exact: true,
    path: "/view-blog",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/blog/viewBlog")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About")),
  },
  {
    exact: true,
    path: "/product",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Product")),
  },
  {
    exact: true,
    path: "/register",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup.js")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forgot-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/reset-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/forgot-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forgot-password/index")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/dashboard/index")),
  },
  {
    exact: true,
    path: "/settings",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/settings/profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/settings/editProfile")),
  },
  {
    exact: true,
    path: "/binary-tree-settings",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/settings/BinaryTreeSettings")
    ),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/settings/changePassword")),
  },
  {
    exact: true,
    path: "/change-email-address",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/settings/ChangeEmail")),
  },
  {
    exact: true,
    path: "/change-email-otp-verification",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/settings/OtpVerification")),
  },
  {
    exact: true,
    path: "/referral",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/referral")),
  },
  {
    exact: true,
    path: "/team-referral",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/referral/teamReferal")),
  },
  {
    exact: true,
    path: "/announcements",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Notifications/index")),
  },
  {
    exact: true,
    path: "/reports",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/reports")),
  },
  {
    exact: true,
    path: "/binary-tree",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/binaryTree")),
  },
  {
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/transactionHistory/TransactionHistory")
    ),
  },
  {
    exact: true,
    path: "/kyc",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/KYC/index")),
  },
  {
    exact: true,
    path: "/wallet",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Wallet/index")),
  },
  {
    exact: true,
    path: "/terms&condition",
    guard: false,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/license/termsCondition")),
  },
  {
    exact: true,
    path: "/userserviceagreement",
    guard: false,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/license/userService")),
  },
  {
    exact: true,
    path: "/privacy&policy",
    guard: false,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/license/privacyPolicy")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
